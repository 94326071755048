.about-btn-active{
    background: rgb(131,58,180);
    background: linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%);    color: #fff;
    border: none;
    padding: 10px 20px;
    font-weight: bold;
    border: 1px solid #fff;

}

.about-btn{
    background-color: #ccc;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-weight: bold;
    border: 1px solid #fff;
}

.about-btn:focus{
    outline: none;
}

.about-btn:hover{
    background-color: #aaa;
}

