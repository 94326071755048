.featured-title {
  font-size: 24px;
  font-weight: bold;
}

.featured-description {
  font-size: 12px;
  margin-top: 5px;
  margin-right: 20px;
}

.featured-status {
  font-size: 12px;
  margin-top: 15px;
}

.featured-technology {
  font-size: 11px;
  color: #888;
  margin-bottom: 2px;
}

.featured-technology-icon {
  margin-top: 10px;
}

.featured-btn-active {
  background: rgb(131, 58, 180);
  background: linear-gradient(
    90deg,
    rgba(131, 58, 180, 1) 0%,
    rgba(253, 29, 29, 1) 50%,
    rgba(252, 176, 69, 1) 100%
  );
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-weight: bold;
  border: 1px solid #fff;
  min-height: 50px;
  max-height: 50px;
}

.project-img {
  width: 400px;
  height: 250px;
  object-fit: contain;
}

.featured-btn {
  background-color: #ccc;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-weight: bold;
  border: 1px solid #fff;
  min-height: 50px;
  max-height: 50px;
}

.featured-btn:hover {
  background-color: #aaa;
}

.featured-subTitle {
  font-size: 16px;
  color: #aaa;
}

p.featured-description li {
  color: #555;
}

.featured-box {
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: "rgba(220,220,220,0.3)";
}

.featured-textbox {
  padding: 0 20px;
  width: 100%;
}

.img-btn {
  position: absolute;
  z-index: 10;
  width: 50px;
  height: 100%;
  opacity: 0.5;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.img-btn:hover {
  opacity: 1;
}

.img-btn span {
  font-size: 24px;
  color: #ff0066;
  font-weight: bold;
  margin-top: 30px;
}

.featured-open-btn {
  font-size: 16px;
  font-weight: bold;
  width: 400px;
  color: #ff0066;
  padding-top: 5;
  padding-bottom: 5;
  border: 3px solid #ff0066;
}

.featured-open-btn:hover {
  background-color: #ff0066;
  color: #fff;
}

@media screen and (max-width: 700px) {
  .featured-box {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-left: 0%;
    background-color: "rgba(220,220,220,0.3)";
  }
  .featured-textbox {
    margin-top: 20px;
    margin-left: 0;
    width: 100%;
  }
  .project-img {
    width: 100%;
    height: 250px;
    object-fit: contain;
  }
}
