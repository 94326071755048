@tailwind base;
@tailwind components;
@tailwind utilities;

.animation-pulse {
  /*box-shadow: 0 0 0 20px rgba(229,62,62, 0.5);
    transform: scale(0.8);*/
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.8);
    box-shadow: 0 0 0 0 rgba(229, 62, 62, 1);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 60px rgba(229, 62, 62, 0);
  }

  100% {
    transform: scale(0.8);
  }
}

.txt-shadow {
  text-shadow: #b10000 1px 0 10px;
}

.heart {
  width: 50px;
  height: 50px;
  background-color: red;
  position: relative;
  transform: rotate(-45deg);
  animation: pulse 2s infinite;
  margin: 50px;
}

.heart:before,
.heart:after {
  content: "";
  width: 50px;
  height: 50px;
  background-color: red;
  border-radius: 50%;
  position: absolute;

  top: 0;
}

.heart:before {
  left: 50%;
}

.heart:after {
  top: -50%;
  left: 0;
}
