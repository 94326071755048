body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

button:focus {
  outline: 0 !important;
}

.hero-title {
  font-size: 7vw;
}

.testament-img {
  width: 700px;
}

@media screen and (max-width: 800px) {
  .hero-title {
    font-size: 9vw;
  }
  .testament-img {
    width: 100%;
    padding: 10px;
  }
  .featured-btn-active {
    background: rgb(131, 58, 180);
    background: linear-gradient(90deg, rgba(131, 58, 180, 1) 0%, rgba(253, 29, 29, 1) 50%, rgba(252, 176, 69, 1) 100%);
    color: #fff;
    border: none;
    font-weight: bold;
    border: 1px solid #fff;
    min-height: 50px;
    max-height: 50px;
  }

  .featured-btn {
    background-color: #ccc;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-weight: bold;
    min-height: 120px;
    border: 1px solid #fff;
    height: 40px;
  }
}

@media screen and (max-width: 600px) {
  .hero-title {
    font-size: 13vw;
  }
  .footer-text {
    font-size: 3vw;
  }
}

@import url("https://fonts.googleapis.com/css?family=Lobster&display=swap");

.g-card {
  margin: 20px;
}

.g-card:hover {
  border-radius: 10px;
}

.image-container {
  height: 200px;
  width: 100%;
  object-fit: cover;
}

.icon-class {
  visibility: hidden;
  font-size: 20px;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

.image-container:hover .icon-class {
  visibility: visible;
  font-size: 60px;
  color: grey;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

.image-container:hover {
  border-radius: 10px 10px 0 0px;
}

.nav-link {
  margin-left: 50px;
  padding-left: 10px;
  font-weight: bold;
  padding: 0 20px;
  font-family: "Lobster";
  text-align: center;
}

.nav-link:hover {
  color: #ddd;
  text-align: center;
}

.g-card-image {
  height: 200px;
  width: 100%;
  object-fit: contain;
  border-radius: 10px 10px 0 0;
}

.image-container:hover {
  z-index: 10;
  color: black;
  position: relative;
  margin: auto auto;
  background-color: white;
  opacity: 0.7;
}

.card-container {
  border-radius: 10px;
  max-width: 350px;
  max-height: 100%;
  box-shadow: 0px 0px 3px 1px #ccc;
  margin-bottom: 30px;
  padding-bottom: 20px;
}

.card-container:hover {
  border-radius: 10px;
  box-shadow: 0px 0px 10px 2px #ccc;
}

.g-card-info {
  padding: 0px 15px;
  /* height: 550px; */
}

.g-card-title {
  font-size: 20px;
  margin: 0px;
  font-weight: bold;
}

.g-card-technologies {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.g-card-graphics {
  border: 1px solid #ff0066;
  display: inline-block;
  border-radius: 10px;
  font-size: 12px;
  margin-top: 5px;
  margin-bottom: 0px;
  padding: 5px;
  color: white;
  font-weight: 600;
  background-color: #ff0066;
}

.g-card-description {
  font-size: 12px;
  clear: both;
}

.card-buttons {
  display: flex;
  justify-content: space-between;
  vertical-align: bottom;
  margin-top: 10px;
}

.button-icon {
  margin-right: 3px;
}

.card-button-ind {
  background-color: #fff;
  color: #ff0066;
  padding: 5px 10px;
  width: 100%;
  font-size: 12px;
  border: 3px solid #ff0066;
  margin: 5px;
  border-radius: 5px;
}

.card-button-ind:hover {
  color: white;
  font-size: 12px;
  background-color: #ff0066;
  border: 3px solid #ff0066;
  box-shadow: 0px 0px 10px 1px #ccc;
}

.g-card-sub-title {
  font-size: 12px;
  color: #aaa;
  margin: 0px;
}

.success-msg {
  color: #57a773;
  padding: 10px 15px;
}

.err-msg {
  color: #ee6352;
  padding: 10px 15px;
}

.contact-btn {
  margin-top: 30px;
  background: rgb(131, 58, 180);
  background: linear-gradient(90deg, rgba(131, 58, 180, 1) 0%, rgba(253, 29, 29, 1) 50%, rgba(252, 176, 69, 1) 100%);
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-weight: bold;
  font-size: 24px;
}

.contact-btn:hover {
  box-shadow: 0px 0px 10px 1px #ccc;
}
