.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(6px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.social-media-icon {
  margin-left: 20px;
}

.nav-color {
  width: 100%;
  height: 6px;
  background: rgb(131, 58, 180);
  background: linear-gradient(90deg, rgba(131, 58, 180, 1) 0%, rgba(253, 29, 29, 1) 50%, rgba(252, 176, 69, 1) 100%);
}

.expbutton {
  margin-left: 5px;
  margin-bottom: 10px;
  color: #02c6fc;
  background-color: white;
  border: 2px solid #02c6fc;
  border-radius: 10px;
  outline: none;
  cursor: pointer;
  font-size: 12px;
}

.expButton {
  background-color: #02c6fc;
  color: white;
  border-color: #02c6fc;
}

.graphicbutton {
  margin-left: 5px;
  margin-bottom: 10px;
  color: #ff0066;
  background-color: white;
  border: 2px solid #ff0066;
  border-radius: 10px;
  outline: none;
  cursor: pointer;
  font-size: 12px;
}

.graphicbutton:hover {
  background-color: #ff0066;
  color: white;
  border-color: #ff0088;
}

.profile-image {
  height: 200px;
  width: auto;
  border-radius: 100px;
  box-shadow: 0 8px 6px -6px black;
  margin: 20px 0;
}

@media (max-width: 600px) {
  .profile-image {
    height: 0px;
    width: 0px;
  }
}

.about-header {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
}

.exp-content {
  margin-left: 20px;
}

.bio-title {
  margin-top: 20px;
}

.button-align {
  text-align: center;
  margin-bottom: 30px;
}

.button-style {
  margin: 10px 10px;
  padding: 5px 10px;
  border-radius: 5px;
  border: 5px solid #eee;
  border-radius: 5px;
  font-size: 14px;
  background-color: #fff;
}

.button-style-active {
  margin: 10px 10px;
  padding: 5px 10px;
  border-radius: 5px;
  border: 5px solid #ff0066;

  font-size: 14px;
  background-color: #ff0066;
  color: white;
}

.button-style-active span {
  color: white;
}

.project-title {
  text-align: center;
  color: #ff0066;
  font-family: "Lobster";
  text-transform: capitalize;
}

.project-divider {
  margin-bottom: 40px;
}

.button-header {
  margin: 20px 0;
  text-align: center;
  font-size: 36px;
  font-weight: 600;
  font-family: "Lobster";
}

.about-title {
  padding-top: 40px;
  padding-bottom: 20px;
  font-size: 50px;
  text-align: center;
}

.underline-text {
  border-bottom: 5px solid #ff0066;
}

.contact-button {
  display: block;
  padding: 5px 0;
  width: 100%;
  border-radius: 5px;
  color: white;
  border: 2px solid #02c6fc;
  background-color: #02c6fc;
  font-size: 18px;
}

.contact-button:hover,
.contact-button:active {
  color: white;
  background-color: #0084a8;
  padding: 5px 0;
  border-radius: 5px;
  border: 2px solid #0084a8;
  font-size: 18px;
}

.form-box {
  display: flex;
  flex-direction: column;
}

.form-label {
  display: block;
  margin-top: 10px;
}

.form-textarea {
  min-width: 400px;
  height: 200px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
}

.form-input {
  min-width: 400px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
}

::placeholder {
  padding-left: 5px;
}

.form-align {
  padding-left: 100px;
}

@media screen and (max-width: 600px) {
  .form-align {
    padding-left: 0;
  }
}

.review-img {
  margin: 20px 0;
  display: flex;
  justify-content: center;
}
