.exp-title {
  font-size: 22px;
  font-weight: bold;
  font-family: "Lobster";
}

.exp-month {
  font-size: 16px;
  color: #888;
}

.exp-subtitle {
  font-size: 18px;
  color: #888;
}

.exp-point {
  font-size: 16px;
}

.exp-desc {
  font-size: 16px;
}
